import { Money } from "../../../utils";

interface MoneyInputProps {
  onChange?: (event: any) => void;
  className?: string;
  value: string;
  placeholder?: string;
  errorMessage?: string | null;
  disabled?: boolean
}

const MoneyInput: React.FC<MoneyInputProps> = ({ className, value, onChange, placeholder, errorMessage, disabled = false }) => {
  const borderColor = errorMessage ? 'border-red-600' : 'border-slate-300';

  return (
    <div className="w-full flex flex-col flex-wrap space-y-2">
      <label className="font-bold text-sm opacity-65">{placeholder}</label>
      <input
        className={`${className} w-full rounded-lg border text-sky-950 text-normal ${borderColor}`}
        value={value}
        onChange={(event) => {
          let newValue = '';
          let enteredText = event.target.value;
          if (enteredText.trim() !== '' && enteredText.trim() !== '$') {
            newValue = Money.parseFromCOP(enteredText).toString()
          }
          event.target.value = newValue

          if (onChange) {
            onChange(event);
          }
        }}
        disabled={disabled}
      />
      {errorMessage && <div className="px-2 text-xs text-red-600">{errorMessage}</div>}
    </div>
  )
}

export default MoneyInput;