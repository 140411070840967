import { createSlice } from "@reduxjs/toolkit";

interface ConfigState {
}
const initialState: ConfigState = {
  config: null
};

export default createSlice({
  name: 'configSlice',
  initialState,
  reducers: {
  }
})
