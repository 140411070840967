import { useLoginForm } from '../hooks';
import { Button, Input } from './';
import { Email, InputValidator, Password } from '../../infrastructure/inputs';

const LoginForm: React.FC = () => {
  const { loading, email, password, setEmail, setPassword, submitted, submitForm } = useLoginForm();

  return (
    <form className={`w-full md:w-1/3 flex flex-wrap flex-col bg-white rounded-lg shadow-lg justify-center items-center p-10 space-y-10`} onSubmit={(event) => { event.preventDefault(); submitForm(); }}>
      <h1 className="text-xl font-bold">Admin</h1>

      <Input
        className="px-3 py-3"
        placeholder="Correo electrónico"
        type="email"
        value={email.value}
        errorMessage={submitted ? email.errorMessage() : null}
        onChange={(event) => { setEmail(Email.dirty(event.target.value)); }}
      />

      <Input
        className="px-3 py-3"
        placeholder="Contraseña"
        type="password"
        value={password.value}
        errorMessage={submitted ? password.errorMessage() : null}
        onChange={(event) => { setPassword(Password.dirty(event.target.value)) }}
      />

      <Button
        className='py-3 px-5'
        loading={loading}
        disabled={InputValidator.anyEmpty([email, password])}
        text="Iniciar sesión"
        type='submit'
      />
    </form>
  )
}

export default LoginForm;