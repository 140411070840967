interface IUser {
  id: string;
  createdAt: Date;
  firstName: string;
  secondName?: string;
  firstSurname?: string;
  secondSurname?: string;
  email: string;
  mobilePhone?: string;
  documentNumber?: string;
}

export default class User {
  id: string;
  createdAt: Date;
  firstName: string;
  secondName?: string;
  firstSurname?: string;
  secondSurname?: string;
  email: string;
  mobilePhone?: string;
  documentNumber?: string;

  constructor({
    id, createdAt, firstName,
    secondName, firstSurname,
    secondSurname, email,
    mobilePhone, documentNumber
  }: IUser) {
    this.id = id;
    this.createdAt = createdAt;
    this.email = email;
    this.firstName = firstName;
    this.secondName = secondName;
    this.firstSurname = firstSurname;
    this.secondSurname = secondSurname;
    this.mobilePhone = mobilePhone;
    this.documentNumber = documentNumber
  } 

  get fullName() {
    return `${this.firstName} ${this.secondName ?? ''} ${this.firstSurname ?? ''} ${this.secondSurname ?? ''}`;
  }
}