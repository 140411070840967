export default class Money {
  static formatToCOP = (amount: number): string => {
    return new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
    }).format(amount);
  }

  static parseFromCOP = (formattedAmount: string): number => {
    const numericString = formattedAmount.replace(/[^\d-]/g, '');
    const amount = parseInt(numericString);
    if(isNaN(amount)) throw new Error('Invalid amount');

    return amount;
  };
}