import { useState } from 'react';
import { InputValidator, Number, Text } from '../../infrastructure/inputs';

const useUsersTableFilters = () => {
  const [firstName, setFirstName] = useState(Text.dirtyAllowEmpty(''));
  const [secondName, setSecondName] = useState(Text.dirtyAllowEmpty(''));
  const [firstSurname, setFirstSurname] = useState(Text.dirtyAllowEmpty(''));
  const [secondSurname, setSecondSurname] = useState(Text.dirtyAllowEmpty(''));
  const [email, setEmail] = useState(Text.dirtyAllowEmpty(''));
  const [mobilePhone, setMobilePhone] = useState(Number.dirtyAllowEmpty(''));
  const [documentNumber, setDocumentNumber] = useState(Number.dirtyAllowEmpty(''));
  const [submitted, setSubmitted] = useState(false);

  const clear = () => {
    setFirstName(Text.dirtyAllowEmpty(''));
    setSecondName(Text.dirtyAllowEmpty(''));
    setFirstSurname(Text.dirtyAllowEmpty(''));
    setSecondSurname(Text.dirtyAllowEmpty(''));
    setEmail(Text.dirtyAllowEmpty(''));
    setMobilePhone(Number.dirtyAllowEmpty(''));
    setDocumentNumber(Number.dirtyAllowEmpty(''));
  }

  const validForm = () => {
    return InputValidator.valid([
      firstName, secondName,
      firstSurname, secondSurname,
      email, mobilePhone,
      documentNumber
    ]);
  }

  return {
    firstName, setFirstName,
    secondName, setSecondName,
    firstSurname, setFirstSurname,
    secondSurname, setSecondSurname,
    email, setEmail,
    mobilePhone, setMobilePhone,
    documentNumber, setDocumentNumber,
    submitted, setSubmitted,
    clear, validForm
  };
};

export default useUsersTableFilters;