import { AxiosError } from "axios";
import Constants from "./constants";

export default class JsonUtil {
  static mssgFromError = (error: AxiosError): string => {
    try {
      const message = (error.response!.data as { mssg: string })?.mssg; 
      if (!message) { return Constants.GENERIC_ERROR_MSSG; }

      return message;
    } catch {
      return Constants.GENERIC_ERROR_MSSG;
    }
  }

  static removeBlankFields = (obj: { [key: string]: any }): { [key: string]: any } => {
    const newObj: { [key: string]: any } = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        newObj[key] = obj[key];
      }
    }

    return newObj;
  }
}