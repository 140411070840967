import { ToastPosition, toast } from 'react-toastify';

export default class Alert {
  static showError = (error: string, position: ToastPosition = 'bottom-left', theme: string = 'dark') => {
    toast.error(error, {
      position: position,
      theme: theme,
      autoClose: 2000
    });
  }

  static showSuccess = (message: string, position: ToastPosition = 'bottom-left', theme: string = 'dark') => {
    toast.success(message, {
      position: position,
      theme: theme,
      autoClose: 2000
    });
  }
}