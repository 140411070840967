import { User } from "../../../domain/models";
import { Table } from "..";
import Routes from "../../../config/Routes";
import { NavLink } from "react-router-dom";
import { DateUtil } from "../../../utils";

interface UsersTableProps {
  users: User[] | undefined;
  loading: boolean;
  totalPages: number | undefined;
  currentPage: number | undefined;
  onPageChange: (page: number) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({ users, loading, totalPages, currentPage, onPageChange }) => {
  const columnNames = [
    'Fecha creación',
    'Email',
    'Primer nombre',
    'Segundo nombre',
    'Primer apellido',
    'Segundo apellido',
    'Celular',
    'Cédula',
    'Acciones'
  ];
  let rows: React.ReactNode[][] = [];
  const EMPTY_ROW_VALUE = '-';
  
  users?.forEach((user) => {
    rows.push([
      <div>
        <h2 className="text-xs text-gray-800 dark:text-white">{DateUtil.shortDate(user.createdAt)}</h2>
      </div>,
      <div>
        <h2 className="text-xs text-gray-800 dark:text-white">{user.email}</h2>
      </div>,
      <div>
        <h2 className="text-xs text-gray-800 dark:text-white">{user.firstName}</h2>
      </div>,
      <div>
        <h2 className="text-xs text-gray-800 dark:text-white">{user.secondName ?? EMPTY_ROW_VALUE}</h2>
      </div>,
      <div>
        <h2 className="text-xs text-gray-800 dark:text-white">{user.firstSurname ?? EMPTY_ROW_VALUE}</h2>
      </div>,
      <div>
        <h2 className="text-xs text-gray-800 dark:text-white">{user.secondSurname ?? EMPTY_ROW_VALUE}</h2>
      </div>,
      <div>
        <h2 className="text-xs text-gray-800 dark:text-white">{user.mobilePhone ?? EMPTY_ROW_VALUE}</h2>
      </div>,
      <div>
        <h2 className="text-xs text-gray-800 dark:text-white">{user.documentNumber ?? EMPTY_ROW_VALUE}</h2>
      </div>,
      <NavLink to={Routes.USER_DETAIL_PATH.replace(':id', user.id)} className="text-xs bg-sky-600 hover:bg-sky-800 py-2 px-4 rounded-full text-white font-normal">Ver</NavLink>
    ])
  });

  return(
    <div className="w-full">
      <Table
        loading={loading}
        columnNames={columnNames}
        rows={rows}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange} />
    </div>
  )
}

export default UsersTable;