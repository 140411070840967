interface IPaymentTransaction {
  id: string;
  createdAt: Date;
  succeeded: boolean;
  amount: number;
  _type: string;
}

export default class PaymentTransaction {
  id: string;
  createdAt: Date;
  succeeded: boolean;
  amount: number;
  _type: string;

  constructor({
    id, createdAt, succeeded,
    amount, _type
  }: IPaymentTransaction) {
    this.id = id;
    this.createdAt = createdAt;
    this.succeeded = succeeded;
    this.amount = amount;
    this._type = _type;
  }
}