import { useState } from "react";
import { Date, InputValidator } from "../../infrastructure/inputs";
import { ProjectedPayment } from "../../domain/models";
import { Alert } from "../../utils";
import { RestLoansRepository } from "../../infrastructure/repositories";
import { LoansRepository } from "../../domain/repositories";

const useProjectPayment = (loanId: string) => {
  const [paidAt, setPaidAt] = useState(Date.dirty(""));
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [projectedPayment, setProjectedPaymnt] = useState<ProjectedPayment | undefined>(undefined);

  const loansRepository: LoansRepository = RestLoansRepository.getInstance();

  const projectPayment = async () => {
    setSubmitted(true);
    if (!validForm()) return;

    setLoading(true);
    const [projectedPayment, errorMessage] = await loansRepository.projectPayment(loanId, paidAt.getDate()!);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setProjectedPaymnt(projectedPayment!);
    }
    setLoading(false);
  }

  const validForm = (): boolean => {
    return InputValidator.valid([paidAt]);
  }

  return {
    paidAt, setPaidAt,
    loading, setLoading,
    submitted, setSubmitted,
    projectPayment, projectedPayment
  };
};

export default useProjectPayment;