import BottomNavigationOption from "./BottomNavigationOption";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Routes from "../../../config/Routes";

interface BottomNavigationProps {
  className?: string;
}
const BottomNavigation: React.FC<BottomNavigationProps> = ({ className }) => {
  return (
    <div className={`${className} fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600`}>
      <div className="grid h-full max-w-lg grid-cols-2 mx-auto font-medium">
        <BottomNavigationOption name="Préstamos" to={Routes.HOME_PATH} icon={<LocalAtmIcon />} />
        <BottomNavigationOption name="Usuarios" to={Routes.USERS_PATH} icon={<AccountCircleIcon />} />
      </div>
    </div>
  )
}
export default BottomNavigation;