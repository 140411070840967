const LoanModelResult = () => {
  return (
    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-hidden border border-gray-200 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              <tr className="bg-gray-100">
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">¿Cuantos créditos nos ha pagado antes?</th>
                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">0</td>
              </tr>
              <tr>
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">¿Tiene otros créditos en mora?</th>
                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <div className="rounded-lg bg-red-200 w-fit px-4">
                    SI
                  </div>
                </td>
              </tr>
              <tr className="bg-gray-100">
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Riesgo segun email</th>
                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <div className="rounded-lg bg-red-200 w-fit px-4">
                    RIESGO ALTO
                  </div>
                </td>
              </tr>
              <tr>
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Riesgo segun celular</th>
                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <div className="rounded-lg bg-red-200 w-fit px-4">
                    RIESGO ALTO
                  </div>
                </td>
              </tr>
              <tr className="bg-gray-100">
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Riesgo fraude</th>
                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <div className="rounded-lg bg-red-200 w-fit px-4">
                    RIESGO ALTO
                  </div>
                </td>
              </tr>
              <tr>
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Riesgo segun modelo predictivo</th>
                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <div className="rounded-lg bg-green-200 w-fit px-4">
                    RIESGO BAJO
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default LoanModelResult;