import { configureStore } from "@reduxjs/toolkit";
import { configSlice } from "./slices";

const store = configureStore({
  reducer: {
    configSlice: configSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export default store;

export type RootState = ReturnType<typeof store.getState>;