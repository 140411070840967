import AbstractInput from './AbstractInput';

enum EmailError { empty, format }

export default class Email extends AbstractInput<string, EmailError> {
  REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  empty = () => {
    return this.value === null || this.value === undefined || this.value.trim() === '';
  };

  errorMessage = () => {
    const displayError = this.validator();

    if (displayError === EmailError.empty) return 'El campo correo electrónico es requerido';
    if (displayError === EmailError.format) return 'Correo electrónico no tiene formato correcto';
    return null;
  };

  validator = () => {
    if(!this.allowEmpty) {
      if (this.empty()) return EmailError.empty;
    }
    if (this.empty()) return null;

    if (!this.REGEX.test(this.value)) return EmailError.format;
    
    return null;
  };
}