interface IPaginatedModel<T> {
  data: T[];
  totalPages: number;
  currentPage: number;
}

export default class PaginatedModel<T> {
  data: T[];
  totalPages: number;
  currentPage: number;

  constructor({ data, totalPages, currentPage }: IPaginatedModel<T>) {
    this.data = data;
    this.totalPages = totalPages;
    this.currentPage = currentPage;
  }
}