import { TokenRepository } from "../../domain/repositories";

export default class LocalStorageTokenRepository implements TokenRepository {
  TOKEN_KEY = 'TOKEN_KEY';

  private static instance: LocalStorageTokenRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new LocalStorageTokenRepository();
    }
    return this.instance;
  }

  saveToken = (token: string) => {
    try {
      window.localStorage.setItem(this.TOKEN_KEY, token);
      return true;
    } catch (error) {
      return false;
    }
  };

  removeToken = (): boolean => {
    try {
      window.localStorage.removeItem(this.TOKEN_KEY);
      return true;
    } catch (error) {
      return false;
    }
  }

  getToken = (): string | null => {
    try {
      const item = window.localStorage.getItem(this.TOKEN_KEY);
      return item;
    } catch (error) {
      return null;
    }
  }
}