import AbstractInput from './AbstractInput';

enum SelectorError { empty, invalidOption }

export default class Selector extends AbstractInput<string, SelectorError> {
  options: Array<any> = [];

  empty = () => {
    return this.value === null || this.value === undefined || this.value.trim() === '';
  };

  static dirtyWithOptions = (value: any, options: Array<any>) => {
    const selector = new Selector(value);
    selector.options = options;
    return selector;
  }

  static dirtyWithOptionsAllowEmpty(value: any, options: Array<any>) {
    const selector = new Selector(value);
    selector.options = options;
    selector.allowEmpty = true;
    return selector;
  }

  errorMessage = () => {
    const displayError = this.validator();

    if (displayError === SelectorError.empty) return 'El campo es requerido';
    if (displayError === SelectorError.invalidOption) return 'Opción inválida';
    return null;
  };

  validator = () => {
    if(!this.allowEmpty) {
      if (this.empty()) return SelectorError.empty;
    }
    if (this.empty()) return null;


    if(!this.options.map((option) => (option.toString())).includes(this.value)) return SelectorError.invalidOption;

    return null;
  };

  getNumber = (): number | null => {
    const number = parseInt(this.value);
    if (isNaN(number) || number === undefined) {
      return null;
    }

    return number;
  }
}