import { Money } from '../../utils';
import AbstractInput from './AbstractInput';

enum MoneyValueError { empty, format }

export default class MoneyValue extends AbstractInput<string, MoneyValueError> {
  REGEX = /^\d+$/;

  empty = () => {
    return this.value === null || this.value === undefined || this.value.trim() === '';
  };

  errorMessage = () => {
    const displayError = this.validator();

    if (displayError === MoneyValueError.empty) return 'El campo es requerido';
    if (displayError === MoneyValueError.format) return 'No es un número';
    return null;
  };

  validator = () => {
    if (!this.allowEmpty) {
      if (this.empty()) return MoneyValueError.empty;
    }
    if (this.empty()) return null;

    if (!this.REGEX.test(this.value)) return MoneyValueError.format;

    return null;
  };

  getMoney = (): string | null => {
    const number = parseInt(this.value);
    if (isNaN(number) || number === undefined) {
      return null;
    }

    return Money.formatToCOP(number);
  }
}