import AbstractInput from './AbstractInput';

enum TextError { empty }

export default class Text extends AbstractInput<string, TextError> {
  empty = () => {
    return this.value === null || this.value === undefined || this.value.trim() === '';
  };

  errorMessage = () => {
    const displayError = this.validator();

    if (displayError === TextError.empty) return 'El campo es requerido';
    return null;
  };

  validator = () => {
    if(!this.allowEmpty) {
      if (this.empty()) return TextError.empty;
    }
    if (this.empty()) return null;

    return null;
  };
}