import { useParams } from "react-router-dom";
import { LoanInfo, LoanModelResult, LoanTransactions, PageContainer, LoanApplicationInfo, Splash, LoanPaymentInfo, LoanProjectedPaymentInfo, LoanHistoryTracksTable, LoanProjectPayment } from "../components"
import { useEffect } from "react";
import { useLoanHistoryTracks, useLoans } from "../hooks";
import { DateUtil } from "../../utils";

interface LoanHistoryTracksProps {
  loanId: string;
}

const LoanHistoryTracks: React.FC<LoanHistoryTracksProps> = ({ loanId }) => {
  const {
    historyTracks, loadHistoryTracks, totalPages,
    page, loading
  } = useLoanHistoryTracks(loanId);

  useEffect(() => {
    loadHistoryTracks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoanHistoryTracksTable
      historyTracks={historyTracks}
      loading={loading}
      totalPages={totalPages}
      currentPage={page}
      onPageChange={(page: number) => loadHistoryTracks(page)}
    />
  )
}

const LoanDetailPage = () => {
  const { id } = useParams();
  const { loadLoan, loan, loading, updateLoanFees, updateLoan, resetFees } = useLoans();

  useEffect(() => {
    loadLoan(id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !loan) {
    return (<Splash />)
  }

  return (
    <PageContainer className="flex-col space-y-10">
      <div className="flex-col space-y-5">
        <div className="flex flex-wrap flex-col space-y-2">
          <h1 className="text-4xl font-bold">Detalle de préstamo</h1>
          <h2 className="text-base"><strong>ID: </strong>{id}</h2>
          <h2 className="text-base"><strong>Fecha creación: </strong>{DateUtil.formatDate(loan.createdAt)}</h2>
        </div>
      </div>

      <div className="flex-col bg-white rounded-lg p-10 space-y-4">
        <h2 className="text-xl font-bold">Resultado de modelos</h2>
        <LoanModelResult />
      </div>

      <div className="flex flex-col md:flex-row space-y-10 space-x-0 md:space-x-10 md:space-y-0">
        <div className="flex flex-col w-full md:w-1/2 bg-white rounded-lg">
          <LoanInfo loan={loan} updateLoan={updateLoan} />
        </div>

        <div className="flex flex-col w-full md:w-1/2 bg-white rounded-lg p-10 space-y-10">
          {
            loan.amountApproved && loan.amountToPay ? (
              <LoanPaymentInfo loan={loan} updateLoanFees={updateLoanFees} resetFees={resetFees} />
            ) : (
              <LoanProjectedPaymentInfo loanApplication={loan.loanApplication} />
            )
          }
          <LoanApplicationInfo loanApplication={loan.loanApplication} />
        </div>
      </div>


      <div className="flex-col bg-white rounded-lg p-10 space-y-4">
        <h2 className="text-xl font-bold">Transacciones</h2>
        <LoanTransactions loanId={loan.id} />
      </div>

      {
        false && loan ? (
          <div className="flex-col bg-white rounded-lg p-10 space-y-4">
            <h2 className="text-xl font-bold">Historial de cambios</h2>
            <LoanHistoryTracks loanId={loan!.id} />
          </div>
        ) : null
      }

      <div className="flex-col bg-white rounded-lg p-10 space-y-4">
        <h2 className="text-xl font-bold">Proyectar pago para fecha</h2>
        <LoanProjectPayment loan={loan} />
      </div>      

    </PageContainer>
  )
}

export default LoanDetailPage;