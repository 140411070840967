import { TokenRepository } from '../../domain/repositories';
import { LocalStorageTokenRepository } from '../../infrastructure/repositories';

const useAuth = () => {

  const tokenRepository: TokenRepository = LocalStorageTokenRepository.getInstance();

  const saveToken = (token: string) => {
    tokenRepository.saveToken(token);
  }

  const removeToken = () => {
    tokenRepository.removeToken();
  }

  const getToken = (): string | null => {
    return tokenRepository.getToken();
  }

  const isAuthenticated = (): boolean => {
    return getToken() !== null;
  }

  const logout = () => {
    removeToken();
  }

  return {
    saveToken,
    isAuthenticated,
    getToken,
    logout
  };
};

export default useAuth;