import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks';
import Routes from '../../../config/Routes';

const PrivateRoute = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated()) {
    return <Navigate to={Routes.LOGIN_PATH} replace />;
  }

  return (
    <Outlet />
  );
};

export default PrivateRoute;