export default class AnalyticEvents {
  static START_LOAN_APPLICATION_EVENT = {
    event: 'app_start_loan_application',
    category: 'app',
    label: 'Started loan application'
  };

  static REQUEST_LOAN_EVENT = {
    event: 'app_request_loan',
    category: 'app',
    label: 'Requested loan'
  };

  static LOGIN_EVENT = {
    event: 'app_login',
    category: 'app',
    label: 'Logged in'
  };

  static SIGNUP_EVENT = {
    event: 'app_signup',
    category: 'app',
    label: 'Signed up'
  };

  static PAY_LOAN_EVENT = {
    event: 'app_pay_loan',
    category: 'app',
    label: 'Paid loan'
  };
}