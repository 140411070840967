import { LoginForm } from "../components";
import { useEffect } from 'react';
import { useAuth } from "../hooks";
import { useNavigate } from "react-router-dom";
import Routes from "../../config/Routes";

const LoginPage = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(Routes.HOME_PATH, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="flex flex-wrap h-screen justify-center items-center">
      <LoginForm />
    </div>
  )
}

export default LoginPage;