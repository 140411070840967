import { FeeInfo, Loan } from "../../../domain/models";
import { Constants, Money } from "../../../utils";
import { useLoanPaymentInfo } from "../../hooks";
import Button from "../shared/Button";
import MoneyInput from "../shared/MoneyInput";

interface LoanPaymentInfoProps {
  loan: Loan;
  updateLoanFees: (id: string, fees: FeeInfo[]) => void;
  resetFees: (id: string) => void;
}

const LoanPaymentInfo: React.FC<LoanPaymentInfoProps> = ({ loan, updateLoanFees, resetFees }) => {
  const { fees, setFees } = useLoanPaymentInfo(loan);
  
  return (
    <form className="flex flex-col space-y-5 bg-white rounded-lg" onSubmit={(event) => {
      event.preventDefault();
      updateLoanFees(loan.id, fees);
    }}>
      <h2 className="text-xl font-bold">Datos reales de pago</h2>
      <div className="flex flex-col space-y-3 bg-gray-100 p-4 rounded-lg">
        <div><strong>Días solicitados:</strong> {loan.days}</div>
        <div><strong>Monto solicitado:</strong> {loan.loanApplication.amount ? Money.formatToCOP(loan.loanApplication.amount) : ''}</div>
        <div><strong>Monto aprobado:</strong> {loan.amountApproved ? Money.formatToCOP(loan.amountApproved) : ''}</div>
        <div className="border-t border-gray-300 my-4"></div>
        <div>
          <MoneyInput value={Money.formatToCOP(loan.chargedInterest ?? 0) ?? '$'} placeholder="Interés" className="p-2" disabled/>
        </div>
        {
          fees.map(fee => (
            <MoneyInput
              key={fee.fee.code}
              onChange={(e) => {
                const index = fees.findIndex(f => f.fee.code === fee.fee.code);
                fees[index].amount = (e.target.value.trim() !== '$' && e.target.value.trim() !== '') ? Money.parseFromCOP(e.target.value) : 0;
                setFees([...fees]);
              }}
              value={Money.formatToCOP(fee.amount) ?? '$'}
              placeholder={fee.fee.name}
              className="p-2"
              disabled={fee.fee.code === Constants.IVA_FEE_CODE}
            />
          ))
        }
        <div className="border-t border-gray-300 my-4"></div>
        <div><strong>Total:</strong> {loan.amountToPay ? Money.formatToCOP(loan.amountToPay) : ''}</div>
        <div className="border-t border-gray-300 my-4"></div>
        <div className="flex w-full items-center justify-center space-x-2">
          <Button className="text-md py-2 px-4 w-fit" disabled={false} text="Guardar" type="submit" />
          <Button className="text-md py-2 px-4 w-fit" color="red" disabled={false} text="Re calcular fees" type="button" onClick={() => { resetFees(loan.id) }} />
        </div>
      </div>
    </form>
  )
}

export default LoanPaymentInfo;