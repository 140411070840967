import { getUsersFilter } from "../../domain/repositories/UsersRepository";
import { UsersTable, UsersTableFilters, PageContainer } from "../components"
import { useUsers } from "../hooks";
import { useEffect } from "react";

const UsersPage = () => {
  const {
    users, totalPages, page, setPage,
    loadUsers, loading, filter,
    setFilter, clear
  } = useUsers();

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer className="flex-col">
      <div className="flex-col space-y-5">
        <div className="flex flex-wrap flex-col space-y-2">
          <h1 className="text-4xl font-bold">Usuarios</h1>
          <h2 className="text-base">Listado de usuarios</h2>
        </div>

        <UsersTableFilters
          onSearch={(filter: getUsersFilter) => {
            setFilter(filter);
            setPage(1);
            loadUsers(1, filter);
          }}
          onClearFilter={() => {
            clear();
          }}
        />
      </div>

      <UsersTable
        users={users}
        loading={loading}
        totalPages={totalPages}
        currentPage={page}
        onPageChange={(page: number) => loadUsers(page, filter)}
      />
    </PageContainer>
  )
}

export default UsersPage;