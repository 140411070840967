interface IConstructable<T> {
  new(value: any): T;
}

export default abstract class AbstractInput<T, U> {
  value: T;
  allowEmpty: boolean = false;

  constructor(value: T) {
    this.value = value;
  }

  static dirty<Z extends AbstractInput<any, any>>(this: IConstructable<Z>, value: any): Z {
    return new this(value);
  }

  static dirtyAllowEmpty<Z extends AbstractInput<any, any>>(this: IConstructable<Z>, value: any): Z {
    const input = new this(value);
    input.allowEmpty = true;
    return input;
  }

  abstract empty: () => boolean;
  abstract errorMessage: () => string | undefined | null;
  abstract validator: () => U | undefined | null;
}