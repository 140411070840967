export default class RangesUtil {
  static generateRange = (min: number, max: number, step: number): number[] => {
    const range: number[] = [];
    for (let i = min; i <= max; i += step) {
      range.push(i);
    }
    return range;
  }

  static generateRangeAsString = (min: number, max: number, step: number): string[] => {
    return this.generateRange(min, max, step).map((value) => value.toString());
  }
}
