import { User } from "../../../../domain/models";
import { UserEntity } from "../entities";

export default class UserMapper {
  static toModel(entity: UserEntity): User {
    return new User({
      id: entity.id,
      createdAt: entity.created_at,
      email: entity.email,
      firstName: entity.first_name,
      secondName: entity.second_name,
      firstSurname: entity.first_surname,
      secondSurname: entity.second_surname,
      mobilePhone: entity.mobile_phone,
      documentNumber: entity.document_number
    })
  }
}