import { useState } from "react";
import { Loan } from "../../domain/models";

const useLoanPaymentInfo = (loan: Loan) => {
  const [fees, setFees] = useState(loan.fees);

  return {
    fees, setFees
  };
};

export default useLoanPaymentInfo;