import { useState } from 'react';
import { Date, InputValidator, Number } from '../../infrastructure/inputs';

const useLoansTableFilters = () => {
  const [createdAt, setCreatedAt] = useState(Date.dirtyAllowEmpty(''));
  const [estimatedPaymentDate, setEstimatedPaymentDate] = useState(Date.dirtyAllowEmpty(''));
  const [consecutive, setConsecutive] = useState(Number.dirtyAllowEmpty(""));
  const [approvedConsecutive, setApprovedConsecutive] = useState(Number.dirtyAllowEmpty(""));
  const [invoiceConsecutive, setInvoiceConsecutive] = useState(Number.dirtyAllowEmpty(""));
  const [status, setStatus] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const clear = () => {
    setCreatedAt(Date.dirtyAllowEmpty(''));
    setEstimatedPaymentDate(Date.dirtyAllowEmpty(''));
    setConsecutive(Number.dirtyAllowEmpty(""));
    setApprovedConsecutive(Number.dirtyAllowEmpty(""));
    setInvoiceConsecutive(Number.dirtyAllowEmpty(""));
    setStatus(null);
  }

  const validForm = () => {
    return InputValidator.valid([
      createdAt, estimatedPaymentDate,
      consecutive, approvedConsecutive,
      invoiceConsecutive
    ]);
  }

  return {
    createdAt, setCreatedAt,
    estimatedPaymentDate, setEstimatedPaymentDate,
    consecutive, setConsecutive,
    approvedConsecutive, setApprovedConsecutive,
    invoiceConsecutive, setInvoiceConsecutive,
    submitted, setSubmitted,
    status, setStatus,
    clear, validForm
  };
};

export default useLoansTableFilters;