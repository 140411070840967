import { getLoansFilter } from "../../domain/repositories/LoansRepository";
import { LoansTable, LoansTableFilters, PageContainer } from "../components"
import { useLoans } from "../hooks";
import { useEffect } from "react";

const LoansPage = () => {
  const {
    loans, totalPages, page, setPage,
    loadLoans, loading, filter,
    setFilter, clear
  } = useLoans();

  useEffect(() => {
    loadLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer className="flex-col">
      <div className="flex-col space-y-5">
        <div className="flex flex-wrap flex-col space-y-2">
          <h1 className="text-4xl font-bold">Préstamos</h1>
          <h2 className="text-base">Listado de préstamos</h2>
        </div>

        <LoansTableFilters
          onSearch={(filter: getLoansFilter) => {
            setFilter(filter);
            setPage(1)
            loadLoans(1, filter);
          }}
          onClearFilter={() => {
            clear();
          }}
        />
      </div>

      <LoansTable
        loans={loans}
        loading={loading}
        totalPages={totalPages}
        currentPage={page}
        onPageChange={(page: number) => loadLoans(page, filter)}
      />
    </PageContainer>
  )
}

export default LoansPage;