import { DateUtil } from '../../utils';
import AbstractInput from './AbstractInput';

enum DateError { empty, invalidDate }

export default class Date extends AbstractInput<string, DateError> {

  empty = () => {
    return this.value === null || this.value === undefined || this.value.trim() === '';
  };

  errorMessage = () => {
    const displayError = this.validator();

    if (displayError === DateError.empty) return 'El campo es requerido';
    if (displayError === DateError.invalidDate) return 'La fecha no es válida';
    return null;
  };

  validator = () => {
    if(!this.allowEmpty) {
      if (this.empty()) return DateError.empty;
    }
    if (this.empty()) return null;

    if (!DateUtil.isValidDate(this.value)) return DateError.invalidDate;

    return null;
  };

  getDate = () => {
    try {
      return DateUtil.toDate(this.value)
    } catch {
      return null;
    };
  }
}