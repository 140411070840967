import { LoanApplication } from "../../../domain/models";
import { Money } from "../../../utils";

interface LoanProjectedPaymentInfoProps {
  loanApplication: LoanApplication
}

const LoanProjectedPaymentInfo: React.FC<LoanProjectedPaymentInfoProps> = ({ loanApplication }) => {
  return (
    <div className="flex flex-col space-y-5 bg-white rounded-lg">
      <h2 className="text-xl font-bold">Datos proyectados de pago</h2>
      <div className="p-4 rounded-lg bg-yellow-100">
        Esta fue la información mostrada al usuario a la hora de hacer su solicitud.
        <strong> Sin embargo,  la información real de lo que debe depende del monto aprobado.</strong>
      </div>
      <div className="flex flex-col space-y- bg-gray-100 p-4 rounded-lg">
        <div><strong>Días solicitados:</strong> {loanApplication.days}</div>
        <div><strong>Monto solicitado:</strong> {Money.formatToCOP(loanApplication.amount)}</div>
        <div className="border-t border-gray-300 my-4"></div>
        <div><strong>Interés:</strong> {Money.formatToCOP(loanApplication.chargedInterest)}</div>
        {
          loanApplication.fees.map(fee => (
            <div key={fee.fee.code}><strong>{fee.fee.name}:</strong> {Money.formatToCOP(fee.amount)}</div>
          ))
        }
        <div className="border-t border-gray-300 my-4"></div>
        <div><strong>Total:</strong> {Money.formatToCOP(loanApplication.amountToPay)}</div>
      </div>
    </div>

  )
}

export default LoanProjectedPaymentInfo;