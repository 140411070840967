import { Loan } from "../../../domain/models";
import { DateUtil, Money } from "../../../utils";
import { Button, Input, Splash } from "..";
import { Date as DateInput } from "../../../infrastructure/inputs";
import { useProjectPayment } from "../../hooks";
import { LoanStatus } from "../../../domain/models/loan";

interface LoanProjectPaymentProps {
  loan: Loan
}

const LoanProjectPayment: React.FC<LoanProjectPaymentProps> = ({ loan }) => {
  const {
    paidAt, setPaidAt,
    loading, submitted,
    projectPayment, projectedPayment
  } = useProjectPayment(loan.id!);

  if(loading) {
    return (
      <Splash />
    )
  }

  return (
    <div className="flex flex-col space-y-5 bg-white rounded-lg">
      <div className="p-4 rounded-lg bg-yellow-100">
        Solo se puede proyectar el pago cuando el préstamos esta en estado <strong>Depositado</strong>
      </div>
      
      {
        loan.status === LoanStatus.deposited ? (
          <form className="flex flex-col space-y-5 bg-white rounded-lg" onSubmit={(event) => {
            event.preventDefault();
            projectPayment();
          }}>
            <div className="flex flex-col space-y-3 bg-gray-100 p-4 rounded-lg">
              <div><strong>Fecha de desembolso: </strong>{DateUtil.formatDate(loan.depositedAt!)}</div>
              <div className="border-t border-gray-300 my-4"></div>
              <Input
                placeholder="Fecha proyectada de pago"
                className="p-3"
                type="date"
                value={paidAt.value}
                onChange={(event) => { setPaidAt(DateInput.dirty(event.target.value)) }}
                minDate={new Date(loan.depositedAt!)}
                errorMessage={submitted ? paidAt.errorMessage() : null}
              />
              <div className="flex w-full items-center justify-center">
                <Button className="text-md py-2 px-4 w-fit" disabled={false} text="Proyectar" type="submit" />
              </div>

              <div className="border-t border-gray-300 my-4"></div>
              {
                projectedPayment ? (
                  <div className="flex flex-col">
                    <div><strong>Días solicitados: </strong>{projectedPayment.days}</div>
                    <div><strong>Monto aprobado: </strong>{Money.formatToCOP(loan.amountApproved!)}</div>
                    <div><strong>Interés: </strong>{Money.formatToCOP(projectedPayment.chargedInterest)}</div>
                    {
                      projectedPayment.fees.map(fee => (
                        <div key={fee.fee.code}><strong>{fee.fee.name}:</strong> {Money.formatToCOP(fee.amount)}</div>
                      ))
                    }
                    <div className="border-t border-gray-300 my-4"></div>
                    <div><strong>Total: </strong>{Money.formatToCOP(projectedPayment.amountToPay)}</div>
                  </div>
                ) : null
              }
            </div>
          </form>
        ) : null
      }
    </div>

  )
}

export default LoanProjectPayment;