import axios, { AxiosError } from "axios";
import { Constants, Environment, JsonUtil } from "../../../utils";
import { User, PaginatedModel } from "../../../domain/models";
import { UserMapper } from "./mappers";
import { UsersRepository } from "../../../domain/repositories";
import { PaginatedEntity, UserEntity } from "./entities";
import { LocalStorageTokenRepository } from "..";
import { getUsersFilter } from "../../../domain/repositories/UsersRepository";

export default class RestUsersRepository implements UsersRepository {
  private static instance: RestUsersRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RestUsersRepository();
    }
    return this.instance;
  }

  async getUsers(page: number, filter?: getUsersFilter): Promise<[PaginatedModel<User>?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const params = JsonUtil.removeBlankFields({
        page,
        first_name: filter?.firstName,
        second_name: filter?.secondName,
        first_surname: filter?.firstSurname,
        second_surname: filter?.secondSurname,
        email: filter?.email,
        mobile_phone: filter?.mobilePhone,
        document_number: filter?.documentNumber,
        t: token
      });
      const queryString = new URLSearchParams(params).toString();

      const response = await axios.get<PaginatedEntity<UserEntity>>(`${Environment.backendUrl}/admin/v1/users?${queryString}`);
      const users = response.data.data.map((userEntity) => UserMapper.toModel(userEntity));
      const paginatedModel = new PaginatedModel<User>({
        data: users,
        totalPages: response.data.totalPages,
        currentPage: response.data.currentPage
      });

      return [paginatedModel, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }
}