import { HistoryTrack } from "../../../../domain/models";
import { HistoryTrackEntity } from "../entities";

export default class HistoryTrackerMapper {
  static toModel(entity: HistoryTrackEntity): HistoryTrack {
    return new HistoryTrack({
      id: entity.id,
      when: new Date(entity.when),
      fields: entity.fields
    });
  }
}