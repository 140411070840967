import { BeatLoader } from "react-spinners";

interface ButtonProps {
  onClick?: () => void;
  text: String;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  color?: string;
  type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<ButtonProps> = ({ onClick, text, disabled = true, className = '', loading = false, type = 'button', color = 'sky' }) => {
  const style = disabled || loading ? `bg-${color}-600 opacity-60 text-opacity-60` : `bg-${color}-600 hover:bg-${color}-800`;

  return (
    <button
      type={type}
      disabled={disabled || loading}
      className={`${style} ${className} rounded-full text-white text-center justify-center items-center flex`}
      onClick={onClick}
    >
      {
        loading ? (
          <BeatLoader
            color={'white'}
            className="h-6 w-auto justify-center items-center opacity-20"
            aria-label="Loading Spinner"
          />     
        ) : (
            text
        )
      }
    </button>
  )
}

export default Button;