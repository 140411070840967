import { useState } from 'react';
import { UsersRepository } from '../../domain/repositories';
import { User } from '../../domain/models';
import { Alert } from '../../utils';
import { RestUsersRepository } from '../../infrastructure/repositories';
import { getUsersFilter } from '../../domain/repositories/UsersRepository';

const useUsers = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
  const [page, setPage] = useState<number | undefined>(undefined);
  const [filter, setFilter] = useState<getUsersFilter | undefined>(undefined);

  const usersRepository: UsersRepository = RestUsersRepository.getInstance();

  const loadUsers = async (
    page: number = 1,
    filter?: getUsersFilter
  ) => {
    setLoading(true);
    const [paginatedUsers, errorMessage] = await usersRepository.getUsers(
      page, filter
    );

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setUsers(paginatedUsers?.data);
      setTotalPages(paginatedUsers?.totalPages);
      setPage(paginatedUsers?.currentPage);
    }
    setLoading(false);
  };

  const clear = () => {
    setPage(undefined);
    setFilter(undefined);
  }

  return {
    totalPages,
    page,
    setPage,
    loadUsers,
    users,
    loading,
    filter,
    setFilter,
    clear
  };
};

export default useUsers;