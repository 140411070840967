import { useState } from 'react';
import { Email, InputValidator, Password } from '../../infrastructure/inputs';
import { useNavigate } from 'react-router-dom';
import Routes from '../../config/Routes';
import { useAuth } from "./";
import { Alert } from '../../utils';
import { AdminSessionRepository } from '../../domain/repositories';
import { RestAdminSessionsRepository } from '../../infrastructure/repositories';
import { AdminSession } from '../../domain/models';

const useLoginForm = () => {
  const [email, setEmail] = useState(Email.dirty(''));
  const [password, setPassword] = useState(Password.dirty(''));
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();
  const { saveToken } = useAuth();

  const adminSessionRepository: AdminSessionRepository = RestAdminSessionsRepository.getInstance();

  const submitForm = async () => {
    setSubmitted(true);
    if (!validForm()) return;

    setLoading(true);
    const [session, errorMessage]: [AdminSession?, string?] = await adminSessionRepository.login(email.value, password.value);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      saveToken(session!.token);
      navigate(Routes.HOME_PATH, { replace: true });
    }
  };

  const validForm = (): boolean => {
    return InputValidator.valid([email, password]);
  }

  return {
    loading,
    email,
    setEmail,
    password,
    setPassword,
    submitted,
    submitForm
  };
};

export default useLoginForm;