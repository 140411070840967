interface SectionOption {
  value?: any;
  label: string;
}

interface SectionSelectorProps {
  value?: any;
  options: SectionOption[];
  onOptionSelected?: (value?: any) => void;
}

const SectionSelector: React.FC<SectionSelectorProps> = ({ value, options, onOptionSelected }) => {
  const onClick = (option: SectionOption) => {
    if (onOptionSelected) {
      onOptionSelected(option.value);
    }
  }

  return (
    <div className='flex-col md:flex-row flex bg-white rounded-lg md:w-fit border divide-y md:divide-y-0 md:divide-x'>
      {options.map((option, index) => (
        <button
          type='button'
          onClick={() => onClick(option)}
          key={index}
          className={`px-5 py-2 text-xs font-medium text-gray-600 hover:bg-gray-100 ${value === option.value ? 'bg-gray-100' : ''}`}
        >
          {option.label}
        </button>
      ))}
    </div>
  )
}

export default SectionSelector;