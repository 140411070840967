import { BeatLoader } from "react-spinners";

const Splash = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <BeatLoader
        aria-label="Loading Spinner"
       />
    </div>
  )
}

export default Splash;
