export default class Routes {
  static HOME_PATH = '/';
  static LOGIN_PATH = '/login';
  static LOANS_PATH = '/loans';
  static LOAN_DETAIL_PATH = '/loans/:id';
  static USERS_PATH = '/users';
  static USER_DETAIL_PATH = '/users/:id'
  static PURPOSES_PATH = '/purposes';
  static EVENT_EMAILS_PATH = '/event_emails';
  static STATUS_EMAILS_PATH = '/status_emails';
  static CONTRACT_PATH = '/contract';
  static INVOICE_PATH = '/invoice';
}