interface Option {
  value: any;
  label: string;
}

interface OptionInputProps {
  onChange?: (event: any) => void;
  className?: string;
  selectedOption?: string;
  options?: Array<Option>;
  placeholder?: string;
  type?: string;
  errorMessage?: string | null;
  disabled?: boolean;
}

const OptionInput: React.FC<OptionInputProps> = ({ className, options = [], selectedOption = '', onChange, placeholder, errorMessage, disabled = false }) => {
  const textColor = !selectedOption ? 'text-slate-400' : 'text-violet-950';
  const borderColor = errorMessage ? 'border-red-600' : 'border-slate-300';

  return (
    <div className="w-full flex flex-col flex-wrap space-y-2">
      <label className="font-bold text-sm opacity-65">{placeholder}</label>
      <select
        disabled={disabled}
        name="select"
        className={`${className} text-start w-full p-4 rounded-lg border ${textColor} text-normal ${borderColor} ${disabled ? 'bg-gray-200' : ''}`}
        onChange={onChange}
        value={selectedOption}
      >
        <option value="" disabled></option>
        {options?.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
      </select>
      {errorMessage && <div className="px-2 text-xs text-red-600">{errorMessage}</div>}
    </div>
  )
}

export default OptionInput;