import { NavLink } from "react-router-dom";

interface BottomNavigationOptionProps {
  className?: string;
  name: string;
  to: string;
  icon?: React.ReactNode;
}
const BottomNavigationOption: React.FC<BottomNavigationOptionProps> = ({ className, name, to, icon }) => {
  const notSelectedProperties = "text-gray-400 transition-colors duration-300 transform dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700";
  const selectedProperties = "text-gray-700 dark:text-gray-200";
  const baseProperties = "inline-flex flex-col items-center justify-center px-5 group rounded-lg";

  return (
    <NavLink to={to}
      className={({ isActive }) =>
        isActive ? `${className} ${selectedProperties} ${baseProperties}` : `${className} ${notSelectedProperties} ${baseProperties}`
      }>
      {icon}
      <span className="font-medium text-sm">
        {name}
      </span>
      </NavLink>
  )
}
export default BottomNavigationOption;