interface IHistoryTrack {
  id: string;
  when: Date;
  fields: string[];
}

export default class HistoryTrack {
  id: string;
  when: Date;
  fields: string[];

  constructor({
    id, when, fields
  }: IHistoryTrack) {
    this.id = id;
    this.when = when;
    this.fields = fields;
  }
}