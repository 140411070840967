import { FeeInfo, LoanApplication, User } from "./";

export enum LoanStatus {
  open = "Abierto",
  approved = "Aprobado",
  deposited = "Depositado",
  paid = "Pagado",
  rejected = "Rechazado",
  pending = "Pendiente",
  canceled = "Cancelado"
}

export enum PaymentMethod {
  transfer = "Transferencia",
  online = "En línea"
}

interface ILoan {
  id: string;
  createdAt: Date;
  days?: number;
  creditScore?: number;
  estimatedPaymentDate?: Date;
  approvedAt?: Date;
  depositedAt?: Date;
  daysInArrears?: number;
  status: LoanStatus;
  consecutive: number;
  approvedConsecutive?: number;
  invoiceConsecutive?: number;
  amountApproved?: number;
  amountToPay?: number;
  negotiatedAmountToPay?: number;
  negotiatedPaymentDate?: Date;
  annualEffectiveInterestRate?: number;
  chargedInterest?: number;
  amountPaid: number;
  paidAt: Date;
  fees?: FeeInfo[];
  user: User;
  paymentMethod?: PaymentMethod;
  loanApplication: LoanApplication;
}

export default class Loan {
  id: string;
  createdAt: Date;
  days?: number;
  creditScore?: number;
  estimatedPaymentDate?: Date;
  approvedAt?: Date;
  depositedAt?: Date;
  daysInArrears?: number;
  status: LoanStatus;
  consecutive: number;
  approvedConsecutive?: number;
  invoiceConsecutive?: number;
  amountApproved?: number;
  amountToPay?: number;
  negotiatedAmountToPay?: number;
  negotiatedPaymentDate?: Date;
  annualEffectiveInterestRate?: number;
  chargedInterest?: number;
  amountPaid: number;
  paidAt: Date;
  fees: FeeInfo[];
  paymentMethod?: PaymentMethod;
  user: User;
  loanApplication: LoanApplication;

  constructor({
    id, createdAt, days, creditScore, estimatedPaymentDate,
    status, consecutive, approvedConsecutive,
    invoiceConsecutive, amountApproved,
    amountToPay, fees = [],
    annualEffectiveInterestRate, chargedInterest,
    paymentMethod, user, depositedAt,
    negotiatedAmountToPay, negotiatedPaymentDate,
    amountPaid, paidAt,
    loanApplication, approvedAt, daysInArrears
  }: ILoan) {
    this.id = id;
    this.creditScore = creditScore;
    this.createdAt = createdAt;
    this.days = days;
    this.estimatedPaymentDate = estimatedPaymentDate;
    this.depositedAt = depositedAt;
    this.status = status;
    this.daysInArrears = daysInArrears;
    this.consecutive = consecutive;
    this.approvedConsecutive = approvedConsecutive;
    this.invoiceConsecutive = invoiceConsecutive;
    this.amountToPay = amountToPay;
    this.amountApproved = amountApproved;
    this.negotiatedAmountToPay = negotiatedAmountToPay;
    this.negotiatedPaymentDate = negotiatedPaymentDate;
    this.annualEffectiveInterestRate = annualEffectiveInterestRate;
    this.chargedInterest = chargedInterest;
    this.fees = fees;
    this.paymentMethod = paymentMethod;
    this.amountPaid = amountPaid;
    this.paidAt = paidAt;
    this.user = user;
    this.loanApplication = loanApplication;
    this.approvedAt = approvedAt;
  }

  inArrears = (): boolean => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (this.estimatedPaymentDate === undefined || this.estimatedPaymentDate === null) {
      return false;
    }

    const estimatedDate = new Date(this.estimatedPaymentDate);
    return estimatedDate < currentDate && this.status === LoanStatus.deposited;
  }

  static enumToLoanStatusCd = (loanStatus: LoanStatus): number => {
    switch (loanStatus) {
      case LoanStatus.open:
        return 0;
      case LoanStatus.approved:
        return 1;
      case LoanStatus.deposited:
        return 3;
      case LoanStatus.paid:
        return 4;
      case LoanStatus.rejected:
        return 5;
      case LoanStatus.pending:
        return 10;
      case LoanStatus.canceled:
        return 11;
      default:
        throw new Error(`Unknown status code: ${loanStatus}`);
    }
  }

  static loanStatusCds = (): number[] => {
    return Object.values(LoanStatus).map((status) => Loan.enumToLoanStatusCd(status));
  }

  static paymentMethodCds = (): number[] => {
    return Object.values(PaymentMethod).map((method) => Loan.enumToPaymentMethodCd(method));
  }

  static enumToPaymentMethodCd = (paymentMethod: PaymentMethod): number => {
    switch (paymentMethod) {
      case PaymentMethod.transfer:
        return 0;
      case PaymentMethod.online:
        return 1;
      default:
        throw new Error(`Unknown payment method: ${paymentMethod}`);
    }
  }
}