import AbstractInput from './AbstractInput';

export default class InputValidator {
  static valid = (inputs: Array<AbstractInput<any, any>>): boolean => {
    const errorExist = inputs.some(input => {
      if (input.errorMessage()) {
        return true;
      }

      return false;
    });
    
    return !errorExist;
  };

  static anyEmpty = (inputs: Array<AbstractInput<any, any>>): boolean => {
    const emptyExist = inputs.some(input => input.empty());
    return emptyExist;
  }

  static errorMessage = (inputs: Array<AbstractInput<any, any>>): string | undefined | null => { 
    const error = inputs.find(input => input.errorMessage());
    return error ? error.errorMessage() : null;
  }
}