import axios, { AxiosError } from "axios";
import { AdminSessionRepository } from "../../../domain/repositories";
import { Constants, Environment, JsonUtil } from "../../../utils";
import { AdminSession } from "../../../domain/models";
import { AdminSessionEntity } from "./entities";
import { BankAccountMapper } from "./mappers";

export default class RestAdminSessionRepository implements AdminSessionRepository {
  private static instance: RestAdminSessionRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RestAdminSessionRepository();
    }
    return this.instance;
  }

  async login(email: string, password: string): Promise<[AdminSession?, string?]> {
    try {
      const response = await axios.post<AdminSessionEntity>(`${Environment.backendUrl}/admin/v1/admin_sessions`, {
        email, password
      });
      return [BankAccountMapper.toModel(response.data), undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }
}