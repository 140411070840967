import { BottomNavigation, Sidebar } from "../";

const Navigation = () => {
  return (
    <>
      <Sidebar className="hidden lg:flex" />
      <BottomNavigation className="flex lg:hidden" />
    </>
  )
}

export default Navigation;