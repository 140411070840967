import React from "react";

interface TableProps {
  columnNames: string[];
  rows: React.ReactNode[][];
  totalPages?: number;
  currentPage?: number;
  onPageChange?: (page: number) => void;
  loading?: boolean;
}
const Table: React.FC<TableProps> = ({ columnNames, rows, totalPages, currentPage, onPageChange, loading = false }) => {
  return (
    <section className="px-4">
      <div className="flex flex-col mt-6 -mx-0 lg:-mx-4">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle  lg:px-8">
            <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-50 dark:bg-gray-800">
                  <tr>
                    {
                      columnNames.map((name, index) => (
                        <th scope="col" key={index}
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          {name}
                        </th>
                      ))
                    }
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                  {
                    loading ? (
                      Array(rows.length === 0 ? 5 : rows.length).fill(null).map((_, index) => (
                        <tr key={index}>
                          <td colSpan={columnNames.length} className="px-4 py-4 text-sm h-16 animate-pulse bg-slate-200" />
                        </tr>
                      ))
                    ) : (
                      rows.map((rowRowcontent, index) => (
                        <tr key={index}>
                          {
                            rowRowcontent.map((content, index) => (
                              <td className="px-4 py-4 text-sm font-normal text-gray-500 dark:text-gray-400" key={index}>
                                {content}
                              </td>
                            ))
                          }
                        </tr>
                      ))
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {
        !loading && totalPages && currentPage && (
          <div className="mt-6 sm:flex sm:items-center sm:justify-between ">
            <div className="text-sm text-gray-500 dark:text-gray-400">
              <span className="font-medium text-gray-700 dark:text-gray-100">{currentPage} de {totalPages}</span>
            </div>

            <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
              <button
                disabled={currentPage <= 1}
                onClick={() => {
                  if (onPageChange) {
                    onPageChange(currentPage - 1);
                  }
                }}
                className={`${currentPage <= 1 ? 'bg-gray-100' : 'bg-white'} flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                  stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>

                <span>
                  Anterior
                </span>
              </button>

              <button
                disabled={currentPage >= totalPages}
                onClick={() => {
                  if (onPageChange) {
                    onPageChange(currentPage + 1);
                  }
                }}
                className={`${currentPage >= totalPages ? 'bg-gray-100' : 'bg-white'} flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800`}>
                <span>
                  Siguiente
                </span>

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                  stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                </svg>
              </button>
            </div>
          </div>
        )
      }
    </section>
  )
}
export default Table;