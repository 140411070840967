import AbstractInput from './AbstractInput';

enum PasswordError { empty, length }

export default class Password extends AbstractInput<string, PasswordError> {

  empty = () => {
    return this.value === null || this.value === undefined || this.value.trim() === '';
  };

  errorMessage = () => {
    const displayError = this.validator();

    if (displayError === PasswordError.empty) return 'El campo contraseña es requerido';
    if (displayError === PasswordError.length) return 'Contraseña debe tener un mínimo de 6 caracteres';
    return null;
  };

  validator = () => {
    if(!this.allowEmpty) {
      if (this.empty()) return PasswordError.empty;
    }
    if (this.empty()) return null;

    if (this.value.length < 6) return PasswordError.length;

    return null;
  };
}