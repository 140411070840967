import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { SidebarOption } from '../';
import Routes from '../../../config/Routes';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

interface SidebarProps {
  className?: string;
}
const Sidebar: React.FC<SidebarProps> = ({ className }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const logoutAction = () => {
    logout();
    navigate(Routes.LOGIN_PATH, { replace: true });
  }

  return (
    <aside className={`${className} fixed flex flex-col w-64 h-screen px-4 py-8 overflow-y-auto bg-white border-r rtl:border-r-0 rtl:border-l dark:bg-gray-900 dark:border-gray-700`}>
      <NavLink to={Routes.HOME_PATH} className="flex justify-center items-center">
        <strong>Admin</strong>
      </NavLink>
      <div className="flex flex-col justify-between flex-1 mt-6">
        <nav className='space-y-2'>
          <SidebarOption name="Préstamos" to={Routes.HOME_PATH} icon={<LocalAtmIcon />} />
          <SidebarOption name="Usuarios" to={Routes.USERS_PATH} icon={<AccountCircleIcon />} />
          <SidebarOption name="Propósitos" to={Routes.PURPOSES_PATH} icon={<FormatListBulletedIcon />} />
          <SidebarOption name="Emails recordatorio de pago" to={Routes.EVENT_EMAILS_PATH} icon={<MarkEmailUnreadIcon />} />
          <SidebarOption name="Emails cambio de status" to={Routes.STATUS_EMAILS_PATH} icon={<MarkEmailReadIcon />} />
          <SidebarOption name="Contrato" to={Routes.CONTRACT_PATH} icon={<DocumentScannerIcon />} />
          <SidebarOption name="Factura" to={Routes.INVOICE_PATH} icon={<ReceiptIcon />} />
        </nav>
        <button className="flex justify-center text-md items-center text-sky-500 font-bold" onClick={logoutAction}>Cerrar sesión</button>
      </div>
    </aside>
  )
}
export default Sidebar;