export default class Environment {
  static get termsUrl() {
    return `${process.env.REACT_APP_LANDING_URL}/terminos`;
  }

  static get env() {
    return process.env.REACT_APP_ENV;
  }

  static get backendUrl() {
    return process.env.REACT_APP_BACKEND_URL;
  }

  static get v2BackendUrl() {
    return process.env.REACT_APP_V2_BACKEND_URL;
  }
}