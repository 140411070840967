import { Input, Button } from "..";
import { useUsersTableFilters } from "../../hooks";
import { Number, Text } from '../../../infrastructure/inputs';
import { getUsersFilter } from "../../../domain/repositories/UsersRepository";

interface UsersTableProps {
  onSearch: (filter: getUsersFilter) => void;
  onClearFilter: () => void;
}

const UsersTableFilters: React.FC<UsersTableProps> = ({ onSearch, onClearFilter  }) => {
  const {
    firstName, setFirstName,
    secondName, setSecondName,
    firstSurname, setFirstSurname,
    secondSurname, setSecondSurname,
    email, setEmail,
    mobilePhone, setMobilePhone,
    documentNumber, setDocumentNumber,
    submitted, setSubmitted,
    clear, validForm
  } = useUsersTableFilters();

  const submit = () => {
    setSubmitted(true);
    if(!validForm()) return;

    onSearch({
      firstName: firstName.value ?? undefined,
      secondName: secondName.value ?? undefined,
      firstSurname: firstSurname.value ?? undefined,
      secondSurname: secondSurname.value ?? undefined,
      email: email.value ?? undefined,
      mobilePhone: mobilePhone.value ?? undefined,
      documentNumber: documentNumber.value ?? undefined,
    });
  }

  const clearFilters = () => {
    setSubmitted(false);
    clear();
    onClearFilter();
  }

  return (
    <form className='flex flex-col space-y-5' onSubmit={(event) => { event.preventDefault(); submit(); }}>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3">
        <Input
          className="text-sm p-2"
          placeholder="Primer nombre"
          value={firstName.value}
          errorMessage={submitted ? firstName.errorMessage() : null}
          onChange={(event) => { setFirstName(Text.dirtyAllowEmpty(event.target.value)) }}
        />
        <Input
          className="text-sm p-2"
          placeholder="Segundo nombre"
          value={secondName.value}
          errorMessage={submitted ? secondName.errorMessage() : null}
          onChange={(event) => { setSecondName(Text.dirtyAllowEmpty(event.target.value)) }}
        />
        <Input
          className="text-sm p-2"
          placeholder="Primer apellido"
          value={firstSurname.value}
          errorMessage={submitted ? firstSurname.errorMessage() : null}
          onChange={(event) => { setFirstSurname(Text.dirtyAllowEmpty(event.target.value)) }}
        />
        <Input
          className="text-sm p-2"
          placeholder="Segundo apellido"
          value={secondSurname.value}
          errorMessage={submitted ? secondSurname.errorMessage() : null}
          onChange={(event) => { setSecondSurname(Text.dirtyAllowEmpty(event.target.value)) }}
        />
        <Input
          className="text-sm p-2"
          placeholder="Email"
          type="email"
          value={email.value}
          errorMessage={submitted ? email.errorMessage() : null}
          onChange={(event) => { setEmail(Text.dirtyAllowEmpty(event.target.value)) }}
        />
        <Input
          className="text-sm p-2"
          placeholder="Celular"
          type="number"
          value={mobilePhone.value}
          errorMessage={submitted ? mobilePhone.errorMessage() : null}
          onChange={(event) => { setMobilePhone(Number.dirtyAllowEmpty(event.target.value)) }}
        />
        <Input
          className="text-sm p-2"
          placeholder="Cédula"
          type="number"
          value={documentNumber.value}
          errorMessage={submitted ? documentNumber.errorMessage() : null}
          onChange={(event) => { setDocumentNumber(Number.dirtyAllowEmpty(event.target.value)) }}
        />
        <div className="flex flex-row space-x-3 items-center sm:items-start justify-center sm:justify-start sm:mt-8">
          <Button className="text-sm py-1 px-4 h-fit" disabled={false} text="Buscar" type="submit" />
          <button className="text-sm font-normal text-sky-800 hover:text-sky-600 mb-0 mb-1 sm:mt-1" onClick={() => { clearFilters(); }} type="submit">Limpiar filtros</button>
        </div>
      </div>
    </form>
  )
}

export default UsersTableFilters;