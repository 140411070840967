import {
  createBrowserRouter
} from "react-router-dom";
import {
  LoanDetailPage,
  LoansPage,
  LoginPage,
  NotFoundPage,
  UsersPage
} from "../presentation/pages";
import Routes from "./Routes";
import { PrivateRoute } from "../presentation/components";

export default createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute />,
    children: [
      { path: Routes.HOME_PATH, element: <LoansPage /> },
      { path: Routes.LOANS_PATH, element: <LoansPage /> },
      { path: Routes.USERS_PATH, element: <UsersPage /> },
      { path: Routes.LOAN_DETAIL_PATH, element: <LoanDetailPage /> },
    ],
  },
  { path: Routes.LOGIN_PATH, element: <LoginPage /> },
  { path: '*', element: <NotFoundPage /> },
]);
