interface InputProps {
  onChange?: (event: any) => void;
  className?: string;
  value: string;
  placeholder?: string;
  type?: string;
  errorMessage?: string | null;
  minDate?: Date,
  maxDate?: Date,
  disabled?: boolean
}

const Input: React.FC<InputProps> = ({ className, type = 'text', value, onChange, placeholder, errorMessage, minDate, maxDate, disabled = false }) => {
  const borderColor = errorMessage ? 'border-red-600' : 'border-slate-300';

  return (
    <div className="w-full flex flex-col flex-wrap space-y-2">
      <label className="font-bold text-sm opacity-65">{placeholder}</label>
      <input
        autoComplete={ type === 'email' ? 'email' : (type === 'password' ? 'current-password' : 'off') }
        className={`${className} w-full rounded-lg border text-sky-950 text-normal ${borderColor}`}
        type={type}
        value={value}
        onChange={onChange}
        min={minDate?.toISOString().split('T')[0]}
        max={maxDate?.toISOString().split('T')[0]}
        disabled={disabled}
      />
      { errorMessage && <div className="px-2 text-xs text-red-600">{errorMessage}</div> }
    </div>
  )
}

export default Input;