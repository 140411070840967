import { useState } from 'react';
import { LoansRepository } from '../../domain/repositories';
import { FeeInfo, Loan, PaymentTransaction } from '../../domain/models';
import { Alert } from '../../utils';
import { RestLoansRepository } from '../../infrastructure/repositories';
import { getLoansFilter, updateLoanPayload } from '../../domain/repositories/LoansRepository';

const useLoans = () => {
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState<Loan[] | undefined>(undefined);
  const [loan, setLoan] = useState<Loan | undefined>(undefined);
  const [paymentTransactions, setPaymentTransactions] = useState<PaymentTransaction[]>([]);
  const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
  const [page, setPage] = useState<number | undefined>(undefined);
  const [filter, setFilter] = useState<getLoansFilter | undefined>(undefined);

  const loansRepository: LoansRepository = RestLoansRepository.getInstance();

  const loadLoans = async (
    page: number = 1,
    filter?: getLoansFilter 
  ) => {
    setLoading(true);
    const [paginatedLoans, errorMessage] = await loansRepository.getLoans(
      page, filter
    );

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setLoans(paginatedLoans?.data);
      setTotalPages(paginatedLoans?.totalPages);
      setPage(paginatedLoans?.currentPage);
    }
    setLoading(false);
  };

  const clear = () => {
    setPage(undefined);
    setFilter(undefined);
  }

  const loadLoan = async (id: string) => {
    setLoading(true);
    const [loan, errorMessage] = await loansRepository.getById(id);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setLoan(loan);
    }
    setLoading(false);
  }

  const updateLoanFees = async (id: string, fees: FeeInfo[]) => {
    setLoading(true);
    const [loan, errorMessage] = await loansRepository.updateLoanFees(id, fees);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setLoan(loan);
    }
    setLoading(false);
  }

  const resetFees = async (id: string) => {
    setLoading(true);
    const [loan, errorMessage] = await loansRepository.resetFees(id);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setLoan(loan);
    }
    setLoading(false);
  }

  const updateLoan = async (id: string, data: updateLoanPayload) => {
    setLoading(true);
    const [loan, errorMessage] = await loansRepository.updateLoan(id, data);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setLoan(loan);
    }
    setLoading(false);
  }

  const loadPaymentTransactions = async (id: string) => {
    setLoading(true);
    const [transactions, errorMessage] = await loansRepository.getPaymentTransactions(id);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setPaymentTransactions(transactions ?? []);
    }
    setLoading(false);
  }

  return {
    totalPages,
    page,
    setPage,
    loadLoans,
    loans,
    loading,
    filter,
    setFilter,
    clear,
    loadLoan,
    loan,
    updateLoanFees,
    updateLoan,
    resetFees,
    loadPaymentTransactions,
    paymentTransactions
  };
};

export default useLoans;