interface IAdminSession {
  token: string;
}

export default class AdminSession {
  token: string;

  constructor({ token }: IAdminSession) {
    this.token = token;
  }
}